const en = {
  navBar: {
    homePage: "HOME",
    gowy: "INTO WEIYING",
    // summarize: "summarize",
    Cultural: "CULTURAL",
    honor: "HONOR",
    Product: "PRODUCT",
    message: "MESSAGE",
    concat: "CONCAT US",
    about: "ABOUT US",
    lang: "LANGUAGE",
    en: "ENGLISH",
    cn: "CHINESE",
    phone: "phone",
    fax: "sales",
    address: "address",
    "address-desc":
      "82 Haoshan Road, Chucun, High tech Industrial Development Zone, Weihai City, Shandong Province",
    Copyright:
      "© Copyright Weihaiwei Hard Tools Co., Ltd. 2012-2013 Filing Information: Lu ICP Bei No. 12011979",
  },
  rightFloat: {
    wechat: "WeChat",
    official: "WeChat official account",
    phone: "Phone",
    backTop: "TOP",
  },
  inWeiying: {
    Product: "Product Center",
    gowy: "ENTERING WEIHARD",
    "wy-desc":
      "Weihai weihard Tools Co., Ltd., founded in 2000, focuses on the R&D and production of superhard materials (such as diamond and cubic boron nitride) and high-end cemented carbide non-standard tools. The company has 30000 square meters of workshops and more than 300 first-class equipment at home and abroad, forming a perfect tool production line. Its R&D team is led by cutting tool experts who have worked for 30 years, with high technical level and experienced technician team.",
    brief:
      "stick to、innovate、Dream Building Hard Tools Join Hands with You，Baili Diamond，Harmony between heaven, earth, and humanity，Creating brilliance together！",
    viewMore: "view More",
    partner: "PARTNER",
  },
  honor: {
    honor: "HONOR",
    title1: "National High-tech Enterprise",
    desc1:
      "The recognition standards for national high-tech enterprises usually include aspects such as technological innovation capability, research and development investment, intellectual property rights, and the transformation of scientific and technological achievements. Weihard cutting tools have been recognized as a national high-tech enterprise by meeting these standards.",
    title2: "Obtaining ISO9001:2015 Quality Management System Certification",
    desc2:
      "The ISO 9001:2015 Quality Management System Certification covers the design, development, production, and sales of Weihard Tool Company's quality management system, ensuring that the company can provide products and services that meet quality standards throughout the entire value chain.",
  },
  area: {
    area: "APPLICATION AREA",
    "area-desc":
      "The products of Weihard Tool Company play an important role in various fields, providing high-quality solutions for the processing needs of different industries",
    car: "car area",
    "car-desc":
      "In the field of automotive manufacturing, the products of Weihard Cutting Tools Company are widely used for processing automotive engines and their components. These cutting tools can achieve efficient, high-speed, and high-precision processing, improving the production efficiency and product quality of automotive manufacturing.",
    aviation: "aviation area",
    "aviation-desc":
      "In the aerospace field, the cutting tools of Weihard Tool Company also play an important role. They are used for processing aerospace components, such as engine parts, wing structures, etc. These cutting tools need to have high wear resistance, high cutting performance, and stability to meet the strict quality and safety requirements of the aerospace industry.",
    electron: "electron area",
    "electron-desc":
      "In the field of electronics, products from Weihard Tool Company may be used for processing electronic equipment casings, heat sinks, and other components, as well as manufacturing electronic components. These cutting tools need to have precision machining capabilities to ensure the quality and performance of electronic products.",
    medical: "medical area",
    "medical-desc":
      "In the medical field, Weihard Cutting Tools may be used to process components of medical equipment, such as surgical instruments, implants, etc. These cutting tools need to have high precision and cleanliness to ensure the safety and effectiveness of medical equipment.",
    current: "General field",
    "current-desc":
      "In the field of general components, products from Weihard Tool Company may be used to process various general components, such as bearings, gears, etc. These cutting tools need to adapt to the processing needs of different materials and shapes, ensuring the quality and performance of common components.",
  },
  company: {
    company: "Weihaiwei Hard Tools Co., Ltd",
    what_company: "What is Weihai Weihard Tools Co., Ltd",
    desc1:
      "Weihaiwei Hard Tools Co., Ltd. was established in 2000, focusing on the research and production of superhard materials (such as diamond and cubic boron nitride) as well as high-end hard alloy non-standard cutting tools. The company has a 30000 square meter factory and more than 400 first-class domestic and foreign equipment, forming a complete tool production line. The R&D team is led by cutting tool experts who have been in the industry for 30 years, with a high level of technical expertise and an experienced technician team.",
    desc2:
      "The company's main products include diamond cutting tools, cubic boron nitride cutting tools, hard alloy cutting tools, and high-speed steel complex cutting tools, forming a series and large-scale manufacturing service system.",
    desc3:
      "The product series includes turning and boring cutters, slot cutters, machining hardened steel cutting tools, angle forming cutters, high-speed milling inserts, milling cutters, indexable inserts and cutting tools, high-precision composite rotary cutting tools, hard alloy special cutting tools, etc. Can be applied to new energy vehicles, passenger cars, commercial vehicle manufacturing, consumer electronics industry, aerospace, heavy industry, etc.",
  },
  service: {
    service: "SERVICE ADVANTSAGES",
    desc: "Weihard Tools is committed to providing customized solutions for customers",
    advantage1:
      "Provide customers with comprehensive technical support from the overall tool solution of machine tool turnkey projects to service and on-site debugging",
    advantage2:
      "Research details for stable product quality, improve details, and continuously enhance product quality",
    advantage3:
      "Continuously optimizing tool performance to improve efficiency, improve machining accuracy, and reduce single piece costs for customers",
    advantage4:
      "Fast response speed and short supply cycle. Urgent customer needs, establish emergency green channels to achieve fast supply",
    advantage5:
      "Solve machining difficulties for customers through tool research and provide technical support to customers",
  },
  experience: {
    title: "DEVELOPMENT HISTORY",
    desc: "Over the past 20 years of development, Weihard Tools has been continuously committed to technological innovation and product optimization.",
  },
  culture: {
    title: "COMPANY CULTURE",
    desc: "Striving ceaselessly, Weiye excels.",
    "culture-title1": "Our Vision",
    "culture-content1":
      "Let every car in the world bear a powerful imprint - everything for this dream!",
    "culture-title2": "Our Spirit",
    "culture-content2": "constantly strive to become stronger",
    "culture-title3": "Our slogan",
    "culture-content3": "Persist, innovate, and build dreams",
    honor: "HONOR",
    desc2:
      "In the past 20 years of development, Weihard Tools has been continuously committed to technological innovation and product optimization.",
  },
  Product: {
    Location: "Location",
    title: "Product Center",
    desc: "Provide high-end precision cutting tools and cutting solutions for the global manufacturing market",
    quotation: "Request for quotation",
    download: "Data download",
    "shop-desc": "PRODUCT INTRODUCTION",
    callTime: "24-hour national consultation hotline",
    understand: "Learn more",
  },
  msg: {
    title: "MESSAGE",
    time: "24/7 online, dedicated to serving you",
    desc: "Fill in the following information and we will contact you in the shortest possible time (strictly protect information from leakage, please rest assured to fill it out)",
    youName: "you Name",
    placeholderName: "Please enter your name",
    youPhone: "you Phone",
    placeholderPhone: "Please enter your phone number",
    type: "product Type",
    placeholderType: "Please select product type",
    demand: "you Demand",
    placeholderDemand: "Please enter your requirements",
    submit: "Submit requirement proposal",
    contcat: "Contact Us",
    phone: "phone",
    information: "information",
    email: "email",
    address: "address",
    "address-detail":
      "82 Haoshan Road, Chucun, High tech Industrial Development Zone, Weihai City, Shandong Province",
    communicate: "Online communication with technical consultants",
    "communicate-btn": "Immediate online consultation",
  },
  contact: {
    company: "Weihaiwei Hard Tools Co., Ltd",
    address:
      "82 Haoshan Road, Chucun, High tech Industrial Development Zone, Weihai City, Shandong Province",
    phone: "Phone",
    Navigation: "Navigation Now",
  },
  diaLog: {
    Cancel: "Cancel",
    Confirm: "Confirm",
    title: "contact information",
  },
};
export default en;
