import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);
// 引入element plus
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// 引入icon
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// 滚动组件
import vue3SeamlessScroll from "vue3-seamless-scroll";

// 中英文切换
import I18n from "@/lang/i18n.js";
app
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(vue3SeamlessScroll)
  .use(I18n)
  .mount("#app");
