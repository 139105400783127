<!--  -->
<template>
  <div class="move-bar-main">
    <div class="more-item">
      <div class="flex flex-row items-start justify-between">
        <div @click="goPush('/web')">
          <img src="../../assets/logo3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="more-item">
      <div class="flex flex-row items-start justify-between">
        <div @click="goPush('/web/advantage')">
          {{ $t("navBar.gowy") }}
        </div>
        <el-icon @click="openSelect(1)"><ArrowDown /></el-icon>
      </div>
      <div class="child-item" v-show="openSelectIndex == 1">
        <div @click="goPush('/web/culture#Cultural')">
          {{ $t("navBar.Cultural") }}
        </div>
        <div @click="goPush('/web/culture#honor')">
          {{ $t("navBar.honor") }}
        </div>
      </div>
    </div>

    <div class="more-item">
      <div class="flex flex-row items-start justify-between">
        <div @click="goPush('/web/product')">
          {{ $t("navBar.Product") }}
        </div>
        <el-icon @click="openSelect(2)"><ArrowDown /></el-icon>
      </div>
      <div class="child-item" v-show="openSelectIndex == 2">
        <div
          v-for="(item, index) in prodItems"
          :key="index"
          @click="goPush(item.url)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="more-item">
      <div class="flex flex-row items-start justify-between">
        <div>{{ $t("navBar.concat") }}</div>
        <el-icon @click="openSelect(3)"><ArrowDown /></el-icon>
      </div>
      <div class="child-item" v-show="openSelectIndex == 3">
        <div class="flex flex-row items-center hover:text[#fed83d]">
          <img src="../../assets/icon/call.png" alt="" />
          <div>{{ $t("navBar.phone") }}：0631-5685601</div>
        </div>
        <div class="flex flex-row items-center hover:text[#fed83d]">
          <img src="../../assets/icon/fax.png" alt="" />
          <div>{{ $t("navBar.fax") }}：0631-5683191</div>
        </div>
        <div class="flex flex-row items-start hover:text[#fed83d]">
          <img src="../../assets/icon/location.png" alt="" />
          <div>{{ $t("navBar.address") }}：{{ $t("navBar.address-desc") }}</div>
        </div>
      </div>
    </div>
    <div class="more-item copyright">
      {{ $t("navBar.Copyright") }}
    </div>
  </div>

  <!-- pc端 -->
  <div class="pc-bar-main">
    <div class="bar-box flex flex-row items-start justify-around">
      <div class="bar-content">
        <div @click="goPush('/web')">
          <img src="../../assets/logo2.png" alt="威硬工具" />
        </div>
        <div style="line-height: 1.5; margin: 18px auto">
          {{ $t("inWeiying.brief") }}
        </div>
        <div class="about" @click="goPush('/web/advantage')">
          {{ $t("navBar.about") }}
        </div>
      </div>
      <!-- 走进威硬 -->
      <div class="bar-content">
        <div class="bar-title" @click="goPush('/web/advantage')">
          {{ $t("navBar.gowy") }}
        </div>
        <ul>
          <div>
            <li @click="goPush('/web/culture#Cultural')">
              {{ $t("navBar.Cultural") }}
            </li>
          </div>
          <div>
            <li @click="goPush('/web/culture#honor')">
              {{ $t("navBar.honor") }}
            </li>
          </div>
        </ul>
      </div>
      <!-- 产品中心 -->
      <div class="bar-content">
        <div class="bar-title" @click="goPush('/web/product')">
          {{ $t("navBar.Product") }}
        </div>
        <ul>
          <div
            v-for="(item, index) in prodItems"
            :key="index"
            @click="goPush(item.url)"
          >
            <li>{{ item.title }}</li>
          </div>
        </ul>
      </div>
      <!-- 联系我们 -->
      <div class="concat bar-content">
        <div class="bar-title" @click="goPush('/web/concat')">
          {{ $t("navBar.concat") }}
        </div>
        <div class="flex flex-row items-center hover:text[#fed83d]">
          <img src="../../assets/icon/call.png" alt="" />
          <div>{{ $t("navBar.phone") }}：0631-5685601</div>
        </div>
        <div class="flex flex-row items-center hover:text[#fed83d]">
          <img src="../../assets/icon/fax.png" alt="" />
          <div>{{ $t("navBar.fax") }}：0631-5696997</div>
        </div>
        <div class="flex flex-row items-start hover:text[#fed83d]">
          <img src="../../assets/icon/location.png" alt="" />
          <div>{{ $t("navBar.address") }}：{{ $t("navBar.address-desc") }}</div>
        </div>
      </div>
      <!-- <div style="width: 400px; height: 150px; background-color: #fff"></div> -->
    </div>
    <div class="copyright">
      {{ $t("navBar.Copyright") }}
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getProductType } from "@/api/indexApi";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const lang = ref();
onMounted(() => {
  lang.value = localStorage.getItem("lang");
  getTypeList();
});
const openSelectIndex = ref();
const openSelect = (e) => {
  if (openSelectIndex.value == e) {
    openSelectIndex.value = "";
  } else {
    openSelectIndex.value = e;
  }
};
const goPush = (url) => {
  if (url.indexOf("#") == -1) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", //smooth 平滑；auto:瞬间
    });
  }
  if (route.fullPath == url) {
    router.go(0);
  } else {
    router.push(url);
  }
};
// 获取类型
const prodItems = ref([]);
const getTypeList = () => {
  getProductType().then((res) => {
    res.data.forEach((item) => {
      prodItems.value.push({
        title: lang.value != "en" ? item.name : item.name_en,
        url: `/web/product?id=${item.id}`,
      });
    });
  });
};
</script>
<style scoped lang="scss">
@media (max-width: 768px) {
  .pc-bar-main {
    display: none;
  }
  .move-bar-main {
    width: 100vw;
    color: #fff;
    background-color: #161b25;
    .more-item {
      padding: 15px;
      border-bottom: 1px #222833 solid;
      font-size: 18px;
      .child-item {
        padding: 20px 15px 10px;
        font-size: 15px;
        line-height: 2;
        img {
          margin: 5px 5px 0 0;
        }
      }
    }
    .copyright {
      text-align: center;
    }
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .move-bar-main {
    display: none;
  }
  .pc-bar-main {
    width: 100vw;
    background-color: #363533;
    color: #aeaeae;
    .bar-box {
      width: 90vw;
      margin: 0 auto;
      padding-top: 60px;
      .bar-content:nth-child(1) {
        margin-right: 12%;
      }
      // .bar-content:nth-child(2) {
      //   width: 20%;
      // }
      .bar-content {
        width: 25%;
        margin: 0 10px;
        font-size: 14px;
        line-height: 2;
        padding-bottom: 40px;
        ul {
          list-style: disc;
          margin-left: 10px;
          li:hover {
            color: #fed83d;
          }
        }
        .bar-title {
          color: #fff;
          font-size: 20px;
          margin-bottom: 20px;
          line-height: 1;
        }
        .about {
          display: inline-block;
          padding: 10px 30px;
          background-color: #fed83d;
          color: #000;
        }
      }
      .concat {
        img {
          margin-right: 10px;
          margin-top: 5px;
        }
      }
    }
    .copyright {
      border-top: 1px #5c5c5c solid;
      font-size: 12px;
      text-align: center;
      padding: 20px 0;
    }
  }
}
@media (min-width: 1025px) {
  .move-bar-main {
    display: none;
  }
  .pc-bar-main {
    width: 100vw;
    background-color: #363533;
    color: #aeaeae;
    .bar-box {
      width: 65vw;
      margin: 0 auto;
      padding-top: 60px;
      .bar-content:nth-child(1) {
        margin-right: 12%;
      }
      // .bar-content:nth-child(2) {
      //   width: 20%;
      // }
      .bar-content {
        width: 25%;
        margin: 0 10px;
        font-size: 14px;
        line-height: 2;
        padding-bottom: 40px;
        ul {
          list-style: disc;
          margin-left: 10px;
          li:hover {
            color: #fed83d;
          }
        }
        .bar-title {
          color: #fff;
          font-size: 20px;
          margin-bottom: 20px;
          line-height: 1;
        }
        .about {
          display: inline-block;
          padding: 10px 30px;
          background-color: #fed83d;
          color: #000;
        }
      }
      .concat {
        img {
          margin-right: 10px;
          margin-top: 5px;
        }
      }
    }
    .copyright {
      border-top: 1px #5c5c5c solid;
      font-size: 12px;
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>
