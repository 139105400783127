import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

const routes = [
  {
    path: "/web",
    name: "home",
    meta: { title: "威硬网站" },
    component: () => import("../views/home.vue"),
  },
  {
    path: "/web/advantage",
    name: "advantage",
    meta: { title: "威硬网站-走进威硬" },
    component: () => import("../views/advantage/index.vue"),
  },
  {
    path: "/web/culture",
    name: "culture",
    meta: { title: "威硬网站-文化荣誉" },
    component: () => import("../views/corporate-culture/index.vue"),
  },
  {
    path: "/web/product",
    name: "product",
    meta: { title: "威硬网站-产品中心" },
    component: () => import("../views/product/index.vue"),
  },
  {
    path: "/web/product-detail",
    name: "product-detail",
    meta: { title: "威硬网站-产品详情" },
    component: () => import("../views/product/detail.vue"),
  },
  {
    path: "/web/leave-msg",
    name: "leave-msg",
    meta: { title: "威硬网站-在线留言" },
    component: () => import("../views/leave-msg/index.vue"),
  },
  {
    path: "/web/concat",
    name: "concat",
    meta: { title: "威硬网站-联系我们" },
    component: () => import("../views/concat/index.vue"),
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "404" },
    component: () => import("../views/notFound/index.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  }
  next(); // 继续路由导航
});

export default router;
