import request from "@/utils/request";

// 获取banner
export function getBanner(data) {
  return request({
    url: "/api/index/banner",
    method: "POST",
    data,
  });
}

// 获取产品分类
export function getProductType(data) {
  return request({
    url: "/api/index/p_type",
    method: "POST",
    data,
  });
}
// 获取产品列表
export function getProduct(data) {
  return request({
    url: "/api/index/product",
    method: "POST",
    data,
  });
}
// 合作伙伴
export function getPartners(data) {
  return request({
    url: "/api/index/hezuo",
    method: "POST",
    data,
  });
}
// 留言
export function addMsg(data) {
  return request({
    url: "/api/index/liuyan",
    method: "POST",
    data,
  });
}
