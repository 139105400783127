<template>
  <nav v-if="routerPath != '/404'">
    <NavBar></NavBar>
  </nav>
  <body>
    <rightFloat></rightFloat>
    <router-view />
  </body>
  <footer v-if="routerPath != '/404'">
    <bottomBar></bottomBar>
  </footer>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import NavBar from "@/components/navBar/index.vue";
import bottomBar from "@/components/bottomBar/index.vue";
import rightFloat from "@/components/right-Floating/index.vue";
const router = useRouter();
const routerPath = ref();
onMounted(() => {});
watch(
  () => router.currentRoute.value,
  (newValue) => {
    routerPath.value = newValue.path;
  }
);
</script>
<style lang="scss">
@import url("./style/tailwind.css");
@import url("./style/main.css");
.myanimation {
  animation: load 0.5s linear;
  transform: translateY(0px);
  opacity: 1;
}
@keyframes load {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
