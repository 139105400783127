const zh = {
  navBar: {
    homePage: "网站首页",
    gowy: "走进威硬",
    // summarize: "文化概括",
    Cultural: "企业文化",
    honor: "文化荣誉",
    Product: "产品中心",
    message: "在线留言",
    concat: "联系我们",
    about: "关于我们",
    lang: "切换语言",
    en: "英语",
    cn: "中文",
    phone: "行政业务",
    fax: "销售业务",
    address: "地址",
    "address-desc": "山东省威海市高技术产业开发区初村昊山路82号",
    Copyright:
      "© 版权所有 威海威硬工具股份有限公司 2012-2013 备案信息：鲁ICP备12011979号",
  },
  rightFloat: {
    wechat: "微信联系",
    official: "微信公众号",
    phone: "电话资讯",
    backTop: "返回顶部",
  },
  inWeiying: {
    Product: "产品中心",
    gowy: "走进威硬",
    "wy-desc":
      "威海威硬工具股份有限公司成立于2000年， 专注于超硬材料（如金刚石和立方氮化硼） 以及高端硬质合金非标准刀具的研发和生产。 公司拥有30000平方米的厂房和300多台国内外一流设备， 形成了完善的刀具生产线。其研发团队由从业30年的刀具专家领导， 技术水平高，技师队伍经验丰富。",
    brief: "坚守、创新、筑梦威硬工具与您携手，百砺金刚，天地人合，共创辉煌！",
    viewMore: "查看更多",
    partner: "合作伙伴",
  },
  honor: {
    honor: "荣誉证书",
    title1: "国家高新技术企业",
    desc1:
      "国家高新技术企业认定标准通常包括技术创新能力、 研发投入、知识产权、科技成果转化等方面。 威硬刀具通过符合这些标准，获得了国家高新技术企业的认定。",
    title2: "取得ISO9001:2015质量管理体系认证",
    desc2:
      "ISO 9001:2015质量管理体系认证涵盖了威硬刀具公司的质量管理体系的设计 、开发、生产和销售等方面， 确保公司在整个价值链中都能提供符合质量标准的产品和服务。",
  },
  area: {
    area: "应用领域",
    "area-desc":
      "威硬刀具公司的产品在各个领域都发挥着重要作用，为不同行业的加工需求提供了高质量的解决方案",
    car: "汽车领域",
    "car-desc":
      "在汽车制造领域，威硬刀具公司的产品被广泛用于加工汽车发动机及其零部件。这些刀具能够实现高效、高速、高精度的加工，提高汽车制造的生产效率和产品质量。",
    aviation: "航空领域",
    "aviation-desc":
      "在航空航天领域，威硬刀具公司的刀具也扮演着重要角色。它们被应用于加工航空航天零部件，如发动机零件、机翼结构等。这些刀具需要具备高耐磨性、高切削性能和稳定性，以满足航空航天行业对质量和安全性的严格要求。",
    electron: "电子领域",
    "electron-desc":
      "在电子领域，威硬刀具公司的产品可能用于加工电子设备的外壳、散热器等部件，以及加工电子元器件的制造。这些刀具需要具备精密加工能力，确保电子产品的质量和性能。",
    medical: "医疗领域",
    "medical-desc":
      "在医疗领域，威硬刀具公司的刀具可能被用于加工医疗设备的零部件，如手术器械、植入物等。这些刀具需要具备高度的精度和清洁度，以确保医疗设备的安全性和有效性。",
    current: "通用部件",
    "current-desc":
      "在通用部件领域，威硬刀具公司的产品可能被应用于加工各种通用零部件，如轴承、齿轮等。这些刀具需要适应不同材料和形状的加工需求，保证通用部件的质量和性能。",
  },
  company: {
    company: "威海威硬工具股份有限公司",
    what_company: "威海威硬工具股份有限公司是一家什么企业",
    desc1:
      "威海威硬工具股份有限公司成立于2000年， 专注于超硬材料（如金刚石和立方氮化硼）以及高端硬质合金非标准刀具的研发和生产。 公司拥有30,000平方米的厂房和400余台国内外一流设备，形成了完善的刀具生产线。 其研发团队由从业30年的刀具专家领导，技术水平高，技师队伍经验丰富。",
    desc2:
      "公司以金刚石刀具、立方氮化硼刀具、硬质合金刀具、高速钢复杂刀具为主要产品，形成系列化、规模化制造服务体系。",
    desc3:
      " 产品系列包括车镗刀、槽刀、加工淬硬钢刀具、角度成型刀、高速铣刀片、铣刀、可转位刀片和刀具、高精度复合旋转刀具、硬质合金专用刀具等。可应用于新能源汽车、乘用车、商用车制造、消费电子产业、航空航天、重工领域等。",
  },
  service: {
    service: "服务优势",
    desc: "威硬刀具致力于为客户提供定制化的解决方案",
    advantage1:
      "从机床交钥匙的项目整体刀具方案到服务、现场调试，为客户提供全方位技术支持",
    advantage2: "产品质量稳定研究细节，改进细节，产品质量不断提升",
    advantage3: "持续优化刀具性能，为客户提升效率、提高加工精度、降低单件成本",
    advantage4:
      "响应速度快，供货周期短。急客户所急，建立应急绿色通道，实现快速供货",
    advantage5: "通过刀具攻关为客户解决加工难点，对客户进行技术支援",
  },
  experience: {
    title: "发展历程",
    desc: "在过去的20多年发展历程中，威硬刀具持续致力于技术创新和产品优化。",
  },
  culture: {
    title: "企业文化",
    desc: "自强不息，威硬之大有作为",
    "culture-title1": "我们的愿景",
    "culture-content1": "让世界上每一辆汽车都带有威硬烙印——一切为了这个梦想！",
    "culture-title2": "我们的精神",
    "culture-content2": "自强不息",
    "culture-title3": "我们的口号",
    "culture-content3": "坚守、创新、筑梦",
    honor: "企业荣誉",
    desc2: "在过去的20年发展历程中，威硬刀具持续致力于技术创新和产品优化。",
  },
  Product: {
    Location: "您的位置",
    title: "产品中心",
    desc: "为全球制造业市场提供高端精密刀具及切削解决方案",
    quotation: "索要报价",
    download: "资料下载",
    "shop-desc": "商品介绍",
    callTime: "24小时全国资讯热线",
    understand: "了解详情",
  },
  msg: {
    title: "在线留言",
    time: "7*24小时全天在线，竭诚为您服务",
    desc: "填写以下信息，我们将会在最短的时间与您取得联系（严格保护信息不会泄露，请放心填写）",
    youName: "您的姓名",
    placeholderName: "请输入您的姓名",
    youPhone: "手机号码",
    placeholderPhone: "请输入您的手机号",
    type: "产品类型",
    placeholderType: "请选择产品类型",
    demand: "您的需求",
    placeholderDemand: "请输入您的需求",
    submit: "提交需求方案",
    contcat: "联系我们",
    phone: "办公电话",
    information: "业务资讯",
    email: "邮箱",
    address: "地址",
    "address-detail": "山东省威海市高技术产业开发区初村昊山路82号",
    communicate: "与技术顾问在线沟通",
    "communicate-btn": "立即在线咨询",
  },
  contact: {
    company: "威海威硬工具股份有限公司",
    address: "山东省威海市高技术产业开发区初村昊山路82号",
    phone: "联系电话",
    Navigation: "立即导航",
  },
  diaLog: {
    Cancel: "关闭",
    Confirm: "确定",
    title: "联系方式",
  },
};
export default zh;
