<!--  -->
<template>
  <div class="main">
    <div
      class="float-box flex flex-col items-center"
      :class="moveIndex == 1 ? 'hoverBg' : ''"
      @mousemove="mousemove(1)"
      @mouseleave="mouseLeave"
    >
      <img src="../../assets/icon/wechat.png" alt="wechat" />
      <div>{{ $t("rightFloat.wechat") }}</div>
      <div
        class="ewm-mark flex flex-col items-center"
        :style="lang == 'en' ? 'left:-260px' : ''"
        v-if="moveIndex == 1"
      >
        <img src="../../assets/ewm.png" alt="二维码" />
        <div>{{ $t("rightFloat.official") }}</div>
      </div>
    </div>
    <div
      class="float-box flex flex-col items-center"
      :class="moveIndex == 2 ? 'hoverBg' : ''"
      @mousemove="mousemove(2)"
      @mouseleave="mouseLeave"
    >
      <img src="../../assets/icon/phone.png" alt="wechat" />
      <div>{{ $t("rightFloat.phone") }}</div>
      <div
        class="phone-mark flex flex-col"
        :style="lang == 'en' ? 'left:-220px' : ''"
        v-if="moveIndex == 2"
      >
        <div>{{ $t("navBar.phone") }}：0631-5685601</div>
        <div>{{ $t("navBar.fax") }}：0631-5683191</div>
      </div>
    </div>
    <div
      class="float-box flex flex-col items-center"
      :class="moveIndex == 3 ? 'hoverBg' : ''"
      @mousemove="mousemove(3)"
      @mouseleave="mouseLeave"
      v-if="topBackShow"
      @click="backTop"
    >
      <img src="../../assets/icon/top.png" alt="wechat" />
      <div>{{ $t("rightFloat.backTop") }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
const lang = ref();
onMounted(() => {
  lang.value = localStorage.getItem("lang");
  window.addEventListener("scroll", handleScroll, true);
});
const moveIndex = ref();
// 鼠标经过
const mousemove = (index) => {
  moveIndex.value = index;
};
// 鼠标离开
const mouseLeave = () => {
  moveIndex.value = "";
};
// 监听页面滚动
const topBackShow = ref(false);
const handleScroll = () => {
  if (window.scrollY > 0) {
    topBackShow.value = true;
  } else {
    topBackShow.value = false;
  }
};
// 返回页面顶部
const backTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth", //smooth 平滑；auto:瞬间
  });
  moveIndex.value = "";
};
</script>
<style scoped lang="scss">
@media (max-width: 768px) {
  .main {
    position: fixed;
    z-index: 999;
    right: 20px;
    bottom: 10vh;
  }
  .float-box {
    background: #aeaeae;
    border-radius: 50%;
    margin: 10px;
    padding: 5px;
    width: 45px;
    height: 45px;
    color: #fff;
    text-align: cetner;
    font-size: 12px;
    line-height: 1.8;
    div {
      display: none;
    }
  }
  .float-box:nth-child(1) {
    display: none;
  }
  .float-box:nth-child(2) {
    display: none;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .main {
    position: fixed;
    z-index: 999;
    right: 25px;
    top: 40vh;
  }
  .float-box {
    margin: 5px 0;
    padding: 5px 10px;
    background: #aeaeae;
    border-radius: 7px;
    color: #fff;
    text-align: cetner;
    font-size: 14px;
    line-height: 1.8;
    .ewm-mark {
      position: absolute;
      left: -210px;
      top: 0;
      padding: 10px;
      background: #fff;
      color: #000;
      font-size: 18px;
      border-radius: 6px;
      box-shadow: 0 0 5px #c2c0c0;
      img {
        max-width: 150px;
        object-fit: contain;
      }
    }
    .phone-mark {
      position: absolute;
      left: -200px;
      top: 0;
      padding: 15px;
      background: #fff;
      color: #000;
      font-size: 16px;
      border-radius: 6px;
      box-shadow: 0 0 5px #c2c0c0;
    }
  }
  .hoverBg {
    background: #00307f;
  }
}
@media (min-width: 1025px) {
  .main {
    position: fixed;
    z-index: 999;
    right: 25px;
    top: 40vh;
  }
  .float-box {
    margin: 5px 0;
    padding: 5px 10px;
    background: #aeaeae;
    border-radius: 7px;
    color: #fff;
    text-align: cetner;
    font-size: 14px;
    line-height: 1.8;
    position: relative;
    .ewm-mark {
      position: absolute;
      left: -210px;
      top: 0;
      padding: 15px 20px;
      background: #fff;
      color: #000;
      font-size: 18px;
      border-radius: 6px;
      box-shadow: 0 0 5px #c2c0c0;
      line-height: 2;
      img {
        max-width: 150px;
        object-fit: contain;
      }
    }
    .phone-mark {
      position: absolute;
      left: -210px;
      top: 0;
      padding: 15px;
      background: #fff;
      color: #000;
      font-size: 16px;
      border-radius: 6px;
      box-shadow: 0 0 5px #c2c0c0;
    }
  }
  .hoverBg {
    background: #00307f;
  }
}
</style>
