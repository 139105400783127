<template>
  <!-- 移动端 -->
  <div class="move-nav-main">
    <div class="nav-box flex flex-row items-center justify-between">
      <img src="../../assets/logo1.png" alt="logo" @click="goPush('/web')" />
      <div @click="openNav">
        <el-icon v-if="openMore"><Operation /></el-icon>
        <el-icon v-if="!openMore"><Close /></el-icon>
      </div>
    </div>
    <div class="nav-body" :class="{ open: !openMore }">
      <div
        class="nav-item flex flex-row items-center justify-between"
        v-for="(item, index) in navItems"
        :key="index"
        @click="
          index != 5
            ? goPush(item.url)
            : moveChangeLang(lang != 'zh' ? 'zh' : 'en')
        "
      >
        <div>{{ item.text }}</div>
        <el-icon><ArrowRight /></el-icon>
      </div>
    </div>
    <div class="mark" :class="{ open: !openMore }"></div>
  </div>

  <!-- pc端 -->
  <div class="pc-nav-main flex flex-row justify-between">
    <div class="nav-img flex justify-start">
      <img src="../../assets/logo1.png" alt="logo" @click="goPush('/web')" />
    </div>
    <div class="nav-text flex flex-row flex-wrap">
      <div
        class="nav-item flex items-center hover:text-[#00307f]"
        v-for="(item, index) in navItems"
        :key="index"
        @mousemove="navMove(item)"
        @mouseleave="navLeave(item)"
        @click="index != 5 ? goPush(item.url) : ''"
      >
        <div class="">{{ item.text }}</div>
        <div v-if="item.showImage" class="nav-border">
          <img src="../../assets/navborder.png" alt="" ref="navBorder" />
        </div>
        <div v-show="item.showArrow">
          <el-icon><ArrowDownBold /></el-icon>
        </div>
        <!-- :class="index == 2 ? 'prodect ' : ''" -->
        <div
          class="select-box"
          :class="{ prodect: index == 2, open: item.childShow }"
          @mouseleave="navLeave(item, 'child')"
        >
          <div
            class="hover:bg-[#e5eaf2] hover:text-[#00307f]"
            v-for="(it, idx) in item.child"
            :key="idx"
            @click.stop="index != 5 ? goPush(it.url) : changeLang(it.type)"
          >
            {{ it.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue"; // eslint-disable-line no-unused-vars
// 路由跳转
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
// 中英文切换
import { useI18n } from "vue-i18n";
const { locale, t } = useI18n();

import { getProductType } from "@/api/indexApi";

const lang = ref();
onMounted(() => {
  lang.value = localStorage.getItem("lang");
  getTypeList();
});
const navItems = ref([
  {
    id: "1",
    text: t("navBar.homePage"),
    showImage: false,
    showArrow: false,
    url: "/web",
  },
  {
    id: "2",
    text: t("navBar.gowy"),
    url: "/web/advantage",
    showImage: false,
    childShow: false,
    showArrow: true,
    child: [
      // { title: t("navBar.summarize"), url: "/web/culture" },
      { title: t("navBar.Cultural"), url: "/web/culture#Cultural" },
      { title: t("navBar.honor"), url: "/web/culture#honor" },
    ],
  },
  {
    id: "3",
    text: t("navBar.Product"),
    showImage: false,
    showArrow: true,
    childShow: false,
    url: "/web/product",
    child: [],
  },
  {
    id: "4",
    text: t("navBar.message"),
    showImage: false,
    showArrow: false,
    url: "/web/leave-msg",
  },
  {
    id: "5",
    text: t("navBar.concat"),
    showImage: false,
    showArrow: false,
    url: "/web/concat",
  },
  {
    id: "6",
    text: t("navBar.lang"),
    showImage: false,
    showArrow: true,
    childShow: false,
    child: [
      { title: t("navBar.cn"), type: "zh" },
      { title: t("navBar.en"), type: "en" },
    ],
  },
]);
// 鼠标经过
const navMove = (item) => {
  if (!item.showImage && !item.showArrow) {
    item.showImage = true;
    navItems.value.forEach((i) => {
      if (item.id != i.id) {
        i.childShow = false;
      }
    });
  }
  if (item.showArrow) {
    item.childShow = true;
    navItems.value.forEach((i) => {
      if (item.id != i.id) {
        i.childShow = false;
      }
    });
  }
};
// 鼠标离开
const navLeave = (item, type) => {
  item.showImage = false;
  item.childShow = false;
  if (type) {
    item.childShow = false;
  }
};

// 获取类型
const getTypeList = () => {
  getProductType().then((res) => {
    res.data.forEach((item) => {
      navItems.value[2].child.push({
        title: lang.value != "en" ? item.name : item.name_en,
        url: `/web/product?id=${item.id}`,
      });
    });
  });
};
// 前往其他页面
const goPush = (url) => {
  openMore.value = true;
  navItems.value.forEach((item) => {
    item.childShow = false;
  });
  if (url.indexOf("#") != -1) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", //smooth 平滑；auto:瞬间
    });
  }
  if (route.fullPath == url) {
    router.go(0);
  } else {
    router.push(url);
  }
};
// 切换中英文
const changeLang = (val) => {
  locale.value = val;
  localStorage.setItem("lang", val);
  router.go(0);
};

// 展开收缩导航栏
const openMore = ref(true);
const openNav = () => {
  openMore.value = !openMore.value;
};
// 切换语言
const moveChangeLang = (val) => {
  locale.value = val;
  localStorage.setItem("lang", val);
  openNav.value = false;
  router.go(0);
};
</script>
<style scoped lang="scss">
/* 手机端 */
@media (max-width: 768px) {
  .move-nav-main {
    width: 100vw;
    background-color: #fff;
    border: 1px #f2f2f2 solid;
    position: relative;
    img {
      width: 100px;
      object-fit: contain;
    }
    .nav-box {
      padding: 10px;
      overflow-x: hidden;
      background-color: #fff;
      border: 1px #f2f2f2 solid;
      position: relative;
      z-index: 998;
    }
    .nav-body {
      width: 100vw;
      padding: 10px;
      overflow-x: hidden;
      background-color: #fff;
      position: absolute;
      z-index: 999;
      opacity: 0;
      display: none;
      transition: opacity 0.5s ease-out;
      .nav-item {
        padding: 15px 0;
        border-bottom: 1px #f5f6f7 solid;
      }
    }
    .nav-body.open {
      opacity: 1;
      display: block;
    }
    .mark {
      width: 100vw;
      height: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      display: none;
      background: rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }
    .mark.open {
      opacity: 1;
      display: block;
    }
  }

  // pc端样式隐藏
  .pc-nav-main {
    display: none;
  }
}

/* 平板端 */
@media (min-width: 769px) and (max-width: 1024px) {
  .move-nav-main {
    display: none;
  }
  .pc-nav-main {
    width: 90vw;
    margin: 0 auto;
    padding: 20px 0;
    background-color: #fff;
    font-family: PingFang SC, PingFang SC;
    .nav-img {
      width: 20%;
    }
    .nav-text {
      width: 80%;
      font-weight: 400;
      font-size: 12px;
      .nav-item {
        margin: 0 20px;
        position: relative;
        .nav-border {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
        .select-box {
          position: absolute;
          right: -10px;
          top: 30px;
          z-index: 99;
          color: #000;
          background-color: #fff;
          border-radius: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-align: center;
          opacity: 0;
          div {
            padding: 11px 20px;
          }
        }
        .open {
          animation: laod 0.5s forwards;
        }
        @keyframes laod {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .prodect {
          right: -40px;
        }
      }
    }
  }
}

/* 桌面端 */
@media (min-width: 1025px) {
  .move-nav-main {
    display: none;
  }
  .pc-nav-main {
    width: 65vw;
    margin: 0 auto;
    padding: 20px 0;
    background-color: #fff;
    font-family: PingFang SC, PingFang SC;
    .nav-img {
      width: 30%;
    }
    .nav-text {
      width: 65%;
      font-weight: 400;
      font-size: 16px;
      .nav-item {
        margin: 0 20px;
        position: relative;
        .nav-border {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
        .select-box {
          position: absolute;
          right: -10px;
          top: 30px;
          // bottom: -120px;
          z-index: 99;
          color: #000;
          background-color: #fff;
          box-shadow: 0 0 5px #d1d2d3;
          border-radius: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-align: center;
          opacity: 0;
          div {
            padding: 11px 20px;
          }
        }
        .open {
          animation: laod 0.5s forwards;
        }
        @keyframes laod {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .prodect {
          right: -40px;
        }
      }
    }
  }
}
</style>
